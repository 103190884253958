import React from "react";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";

import Content, { SidebarPanel } from "../components/Content";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Carousel from "../components/Carousel";
import PageWrapper from "../components/PageWrapper";
import Badge, { BadgeGroup } from "../components/Badge";

const IndexPage = ({ data, location }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const { title, badges, carousel, sidebar_content } = frontmatter;
  const isCarousel = Boolean(carousel.length);
  const resolveFirst = i => (i === 0 && badges.length ? "first" : "");

  return (
    <Layout>
      <SEO title={title} pathname={location.pathname} />
      <PageWrapper>
        {isCarousel ? <Carousel images={carousel} /> : null}
        <Content>
          <div className="main" dangerouslySetInnerHTML={{ __html: html }} />
          <div className="sidebar">
            <BadgeGroup>
              {badges.map((b, i) => (
                <Badge
                  key={`${i}-badge-content-${title}`}
                  to={b.link.url}
                  headline={b.headline}
                  buttonText={b.link.title}
                  icon={b.icon}
                />
              ))}
            </BadgeGroup>
            {sidebar_content.map(({ body }, i) => (
              <SidebarPanel
                key={`${i}-sidebar-content-${title}`}
                className={resolveFirst(i)}
              >
                <ReactMarkdown source={body} />
              </SidebarPanel>
            ))}
          </div>
        </Content>
      </PageWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { slug: { eq: "home" } }) {
      html
      frontmatter {
        title
        slug
        parent
        carousel {
          image
          alt
          credit {
            name
            url
          }
        }
        sidebar_content {
          body
        }
        badges {
          icon
          link {
            title
            url
          }
          headline
        }
      }
    }
  }
`;

export default IndexPage;
